import Vue from 'vue'
import App from './App.vue'
import router from '@/router/news'
import global from '@/utlis/global.js'
import store from '@/store'
import Meta from 'vue-meta'
import '@/utlis/remcommon.js'
import VueLazyload from 'vue-lazyload'
import { initializeApp} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
Vue.config.productionTip = false
Vue.prototype.$global = global
const loadimage = require('@/assets/news/loading.png')
Vue.prototype.$api = global.api
import '../../utlis/vant'
Vue.use(Meta)
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: loadimage,
	loading: loadimage,
	attempt: 1,
})
const firebaseConfig = {
  apiKey: "AIzaSyBVdS2DexLnxspRKv2sqnokzV0kGT7QDLw",
  authDomain: "mynewvision-e3c2d.firebaseapp.com",
  projectId: "mynewvision-e3c2d",
  storageBucket: "mynewvision-e3c2d.appspot.com",
  messagingSenderId: "271140691519",
  appId: "1:271140691519:web:015a8ab818e51b701d228a",
  measurementId: "G-77W90GB9R8"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
Vue.prototype.$logEvent = (event, params) => {
  logEvent(analytics, event, params);
};
Vue.prototype.$eventrackFb = (msg, method,map = {}) => {
  let params = {
    time: new Date(),
    message: msg,
     method: method,
    ...map,
  };
  logEvent(analytics, msg, params);
};
new Vue({
  router,store,
  render: h => h(App),
}).$mount('#app')
